import Label from "../components/Label";
import api from "../components/api";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { currencyFormat, formatOrderDeliveryType } from "../components/functions";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import PlusMinus from "../components/PlusMinus";
import { Link } from "react-router-dom";
import TimeRange from "../components/TimeRange";
import PaymentPopup from "../components/PaymentPopup";
import Privacy from "../components/Privacy";
import Marketing from "../components/Marketing";
import ReactGA from "react-ga4";
import areaCodes from "../data/areaCodes.json";

function Checkout() {
	const {
		settings,
		setLastOrderId,
		setLoading,
		setConfirm,
		user,
		cart,
		handleEditProduct,
		editCart,
		loadCart,
		lang,
		tempId,
		track,
		reloadUser,
		setLastOrderTotal,
	} = useContext(AppContext);
	const [formTouched, setFormTouched] = useState(false);
	const [code, setCode] = useState("");
	const [creditCode, setCreditCode] = useState("");
	const [newsletter, setNewsletter] = useState(false);
	const [marketing, setMarketing] = useState(false);
	const [paymentPopupVisible, setPaymentPopupVisible] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const scrollContainer = document.getElementById("main");
		scrollContainer.scrollTo(0, 0);

		if (settings && settings.website.google_analytics) {
			ReactGA.event("begin_checkout", {
				currency: "EUR",
				value: cart.total,
			});
		}
	}, []);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (formTouched) {
				sendCustomerForm();
				setFormTouched(false);
			}
		}, 500);

		return () => {
			clearTimeout(delayDebounceFn);
		};
	}, [formTouched]);

	const sendCustomerForm = () => {
		const form = document.getElementById("customer-form");
		const button = form.getElementsByTagName("button");
		button[0].click();
	};

	const handlePayment = async () => {
		setLoading(true);
		const tCart = await loadCart();
		if (tCart.errors.length > 0) {
			scrollToError();
			return;
		}
		if (
			tCart.total == 0 ||
			tCart.payment_method_id == 1 ||
			tCart.payment_method_id == 2 ||
			tCart.payment_method_id == 5
		)
			setConfirm({
				title: <Label number={38} />,
				onConfirm: async () => {
					await checkout();
				},
			});
		else if (tCart.payment_method_id == 999) {
			init_yellgo_pay();
		} else setPaymentPopupVisible(true);
	};

	const init_yellgo_pay = async () => {
		console.log("init_yellgo_pay");
		const request = {
			mode: "single",
			shop_id: cart.shop.id,
			amount: cart.total,
			lang: lang,
			origin: "website",
			customer_id: cart.customer_id,
			data: {},
		};
		console.log(request);
		setLoading(true);
		const response = await api.postProtected("/pay/open/", request);
		setLoading(false);
		console.log(response);
		if (response.success === 0) alert(response.error);
		else {
			console.log(response.url);
			document.location = response.url;
		}
	};

	const checkout = async (payment_id) => {
		setLoading(true);
		let response;
		if (user)
			response = await api.postProtected("/cart/checkout/" + user.id + "/", {
				lang: lang,
				payment_id: payment_id,
			});
		else
			response = await api.post("/temp_cart/checkout/" + tempId + "/", {
				lang: lang,
				payment_id: payment_id,
			});
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else {
			setLastOrderId(response.data.id);
			setLastOrderTotal(cart.total);
			loadCart();
			if (user && (newsletter || marketing)) {
				await updateMarketing();
				await reloadUser();
			}
			navigate("/order_confirmed");
			track();
		}
	};

	const updateMarketing = async () => {
		console.log(newsletter, marketing);
		if (newsletter) {
			const response = await api.postProtected("/customers2/edit/" + user.id + "/", {
				newsletter: 1,
			});
			if (response.success == 0) {
				setConfirm({
					title: "Errore",
					message: response.error,
				});
			}
		}
		if (marketing) {
			const response = await api.postProtected("/customers2/edit/" + user.id + "/", {
				marketing: 1,
			});
			if (response.success == 0) {
				setConfirm({
					title: "Errore",
					message: response.error,
				});
			}
		}
	};

	const hasErrorCode = (code) => {
		return cart.errors.filter((el) => el.code == code).length > 0;
	};

	const getErrorCode = (code) => {
		const filtered = cart.errors.filter((el) => el.code == code);
		return filtered.length > 0 ? filtered[0].message : false;
	};

	const scrollToError = () => {
		if (cart.errors.length > 0) {
			const error = cart.errors[0];
			let el = document.getElementById("box-" + error.code);
			console.log(el);
			if (el) el.scrollIntoView({ behavior: "smooth" });
			else {
				if (!el) el = document.getElementsByClassName("box-" + error.code);
				console.log(el);
				if (el.length > 0) el[0].scrollIntoView({ behavior: "smooth" });
				else
					setConfirm({
						title: "Errore",
						message: error.message,
					});
			}
		}
	};

	const editUser = async (request) => {
		setLoading(true);
		const response = await api.postProtected("/customers2/edit/" + user.id + "/", request);
		if (response.success == 0) {
			setLoading(false);
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		} else loadCart();
	};

	const handleCustomerForm = async (e) => {
		console.log("handleCustomerForm");
		e.preventDefault();
		const data = new FormData(e.target);
		let request = Object.fromEntries(data.entries());
		console.log(request);
		editCart(request);
	};

	const addDiscountFidelity = async (discount_id) => {
		setLoading(true);
		const response = await api.postProtected("/cart/add_discount_fidelity/" + user.id + "/", {
			discount_id: discount_id,
		});
		if (response.success == 0) {
			setLoading(false);
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		} else loadCart();
	};

	const deleteDiscountFidelity = async (item_id) => {
		setConfirm({
			title: <Label number={38} />,
			onConfirm: async () => {
				setLoading(true);
				const response = await api.postProtected(
					"/cart/delete_discount_fidelity/" + user.id + "/",
					{
						item_id: item_id,
					}
				);
				if (response.success == 0) {
					setLoading(false);
					setConfirm({
						title: "Errore",
						message: response.error,
					});
				} else loadCart();
			},
		});
	};

	const addDiscountCode = async () => {
		setLoading(true);
		const response = await api.postProtected("/cart/add_discount_code/" + user.id + "/", {
			code: code,
		});
		if (response.success == 0) {
			setLoading(false);
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		} else {
			loadCart();
			setCode("");
		}
	};

	const deleteDiscountCode = async (item_id) => {
		setConfirm({
			title: <Label number={38} />,
			onConfirm: async () => {
				setLoading(true);
				const response = await api.postProtected(
					"/cart/delete_discount_code/" + user.id + "/",
					{
						item_id: item_id,
					}
				);
				if (response.success == 0) {
					setLoading(false);
					setConfirm({
						title: "Errore",
						message: response.error,
					});
				} else loadCart();
			},
		});
	};

	const addCreditCode = async (item_id) => {
		setLoading(true);
		const response = await api.postProtected("/cart/add_credit_code/" + user.id + "/", {
			code: creditCode,
		});
		if (response.success == 0) {
			setLoading(false);
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		} else {
			loadCart();
			setCreditCode("");
		}
	};

	const findPaymentName = (paymentId) => {
		for (const payment_method of cart.availables_payment_methods) {
			if (payment_method.id == paymentId) return payment_method.label;
		}
	};

	if (!cart) return null;

	return (
		<>
			<div className="page">
				<div className="checkout">
					<div className="columns">
						<div className="content">
							{/*cart.errors.length > 0 && (
								<div className="box">
									<div>
										{cart.errors.map((error, i) => (
											<div key={i}>{error.code + " - " + error.message}</div>
										))}
									</div>
								</div>
										)*/}
							<Link className="button button-secondary button-block" to={"/store"}>
								<Label number={168} />
							</Link>
							<div className="box">
								<h1>
									<Label number={71} />
								</h1>
								<div>
									<div className="label-line">
										<div>
											<Label number={5} />:
										</div>
										<div>
											<Label
												number={formatOrderDeliveryType(cart.delivery_type)}
											/>
										</div>
									</div>
									<div className="label-line">
										<div>
											<Label number={9} />:
										</div>
										<div>{cart.shop && cart.shop.name}</div>
									</div>
									{cart.delivery_type == "delivery" && (
										<div className="label-line">
											<div>
												<Label number={12} />:
											</div>
											<div>
												{cart.selected_address &&
													cart.selected_address.address +
														" " +
														cart.selected_address.number +
														", " +
														cart.selected_address.city +
														(cart.selected_address.doorphone
															? " (" +
															  cart.selected_address.doorphone +
															  ")"
															: "")}
											</div>
										</div>
									)}
									<div className="label-line">
										<div>
											<Label number={10} />:
										</div>
										<div>
											{moment(
												cart.delivery_date,
												"YYYY-MM-DD HH:mm:ss"
											).format("DD/MM/YYYY")}
										</div>
									</div>
									<div className="label-line">
										<div>
											<Label number={11} />:
										</div>
										<div>
											<TimeRange
												date={cart.delivery_date}
												range={cart.delivery_time_range}
											/>
										</div>
									</div>
								</div>
							</div>
							{user && cart.customer && (
								<div
									id="box-008"
									className={
										"box" +
										(hasErrorCode("008") || !cart.customer.area_code
											? " has-error"
											: "")
									}
								>
									<h1>
										<Label number={56} />
									</h1>
									<div className="mobile-container">
										<div className="input-group">
											<label>
												<Label number={203} />
											</label>
											<select
												name="area_code"
												defaultValue={cart.customer.area_code}
												onChange={(e) =>
													editUser({ area_code: e.target.value })
												}
											>
												<option value=""></option>
												{areaCodes.map((el, i) => (
													<option key={i} value={el.dial_code}>
														{el.code + " (" + el.dial_code + ")"}
													</option>
												))}
											</select>
										</div>
										<div className="input-group">
											<label>
												<Label number={203} />
											</label>
											<input
												name="mobile"
												type="tel"
												onBlur={(e) => editUser({ mobile: e.target.value })}
												defaultValue={cart.customer.mobile}
											/>
										</div>
									</div>
									{!cart.customer.area_code && (
										<div className="error-label">
											<Label number={206} />
										</div>
									)}
									{getErrorCode("008") && (
										<div className="error-label">{getErrorCode("008")}</div>
									)}
								</div>
							)}
							{!user && (
								<form
									id="customer-form"
									className={
										"box box-012 box-013 box-014 box-008" +
										(hasErrorCode("012") ||
										hasErrorCode("013") ||
										hasErrorCode("014") ||
										hasErrorCode("008")
											? " has-error"
											: "")
									}
									onSubmit={handleCustomerForm}
								>
									<h1>
										<Label number={90} />
									</h1>
									<div className="input-group">
										<label>
											<Label number={54} />
										</label>
										<input
											type="text"
											name="name"
											//onChange={(e) => setFormTouched(true)}
											onBlur={(e) => sendCustomerForm()}
											defaultValue={cart.name}
										/>
										{getErrorCode("012") && (
											<div className="error-label">{getErrorCode("012")}</div>
										)}
									</div>
									<div className="input-group">
										<label>
											<Label number={55} />
										</label>
										<input
											type="text"
											name="surname"
											onBlur={(e) => sendCustomerForm()}
											defaultValue={cart.surname}
										/>
										{getErrorCode("013") && (
											<div className="error-label">{getErrorCode("013")}</div>
										)}
									</div>
									<div className="input-group">
										<label>
											<Label number={91} />
										</label>
										<input
											type="email"
											name="email"
											onBlur={(e) => sendCustomerForm()}
											defaultValue={cart.email}
										/>
										{getErrorCode("014") && (
											<div className="error-label">{getErrorCode("014")}</div>
										)}
									</div>
									<div className="mobile-container">
										<div className="input-group">
											<label>
												<Label number={203} />
											</label>
											<select
												name="area_code"
												defaultValue={cart.area_code}
												onChange={(e) => sendCustomerForm()}
											>
												<option value=""></option>
												{areaCodes.map((el, i) => (
													<option key={i} value={el.dial_code}>
														{el.code + " (" + el.dial_code + ")"}
													</option>
												))}
											</select>
											{getErrorCode("016") && (
												<div className="error-label">
													{getErrorCode("016")}
												</div>
											)}
										</div>
										<div className="input-group">
											<label>
												<Label number={56} />
											</label>
											<input
												type="tel"
												name="mobile"
												onBlur={(e) => sendCustomerForm()}
												defaultValue={cart.mobile}
											/>
											{getErrorCode("008") && (
												<div className="error-label">
													{getErrorCode("008")}
												</div>
											)}
										</div>
									</div>
									<button>Salva</button>
								</form>
							)}{" "}
							{cart.hotel_id && (
								<div
									id="box-015"
									className={"box" + (hasErrorCode("015") ? " has-error" : "")}
								>
									<h1>
										<Label number={190} />
									</h1>
									{getErrorCode("015") && (
										<div className="error-label">{getErrorCode("015")}</div>
									)}
									<input
										type="text"
										onBlur={(e) => editCart({ room: e.target.value })}
										defaultValue={cart.room}
									/>
								</div>
							)}
							<div
								id="box-010"
								className={"box" + (hasErrorCode("010") ? " has-error" : "")}
							>
								<h1>
									<Label number={83} />
								</h1>
								{getErrorCode("010") && (
									<div className="error-label">{getErrorCode("010")}</div>
								)}
								<select
									onChange={(e) =>
										editCart({ payment_method_id: e.target.value })
									}
									value={cart.payment_method_id ? cart.payment_method_id : ""}
									//defaultValue={cart.payment_method_id}
								>
									<option value=""></option>
									{cart.availables_payment_methods.map((payment_method, i) => (
										<option key={i} value={payment_method.id}>
											{payment_method.label}
										</option>
									))}
								</select>
								{settings.credit?.active == 1 && cart.customer && (
									<>
										{cart.customer?.credit > 0 && (
											<div className="cols">
												<div>
													<Label number={111} />:{" "}
													<strong>
														{currencyFormat(
															cart.customer.credit,
															cart?.shop?.currency
														)}
													</strong>
												</div>
												<button
													className="button"
													onClick={(e) =>
														editCart({
															credit: cart.credit == 1 ? 0 : 1,
														})
													}
												>
													{cart.credit == 0 ? (
														<Label number={110} />
													) : (
														<Label number={112} />
													)}
												</button>
											</div>
										)}
										<div className="input-group">
											<label>
												<Label number={109} />
											</label>

											<input
												type="text"
												value={creditCode}
												onChange={(e) => setCreditCode(e.target.value)}
											/>
											<button onClick={addCreditCode}>
												<Label number={18} />
											</button>
										</div>
									</>
								)}
							</div>
							{user && (
								<div className="box">
									<h1>
										<Label number={101} />
									</h1>
									<div className="input-group">
										<label>
											<Label number={101} />
										</label>

										<input
											type="text"
											value={code}
											onChange={(e) => setCode(e.target.value)}
										/>
										<button onClick={(e) => addDiscountCode(e.target.value)}>
											<Label number={18} />
										</button>
									</div>
								</div>
							)}
							{user && settings.fidelity?.active == 1 && (
								<div className="box">
									<h1>
										<Label number={95} />
									</h1>
									<div>
										<div>
											<Label number={97} />{" "}
											<strong>{cart.customer?.points}</strong>
										</div>
										<div>
											<Label number={98} />{" "}
											<strong>{cart.fidelity_points}</strong>{" "}
											<Label number={99} />
										</div>
										{cart.lost_points > 0 && (
											<div>
												<Label number={96} />{" "}
												<strong>{cart.lost_points}</strong>{" "}
												<Label number={99} />
											</div>
										)}
									</div>
									{cart.availables_discounts_fidelity?.length > 0 &&
										cart.discounts_fidelity?.length == 0 && (
											<div className="input-group">
												<label>
													<Label number={100} />
												</label>

												<select
													onChange={(e) =>
														addDiscountFidelity(e.target.value)
													}
												>
													<option value=""></option>
													{cart.availables_discounts_fidelity?.map(
														(discount, i) => (
															<option key={i} value={discount.id}>
																{discount.name}
															</option>
														)
													)}
												</select>
											</div>
										)}
								</div>
							)}
							{settings.orders.covers == 1 && (
								<div className="box">
									<h1>
										<Label number={87} />
									</h1>
									<select
										onChange={(e) => editCart({ covers: e.target.value })}
										value={cart.covers ? cart.covers : ""}
									>
										{[...Array(15)].map((x, i) => (
											<option key={i} value={i}>
												{i}
											</option>
										))}
									</select>
								</div>
							)}
							{settings.orders.chopsticks == 1 && (
								<div className="box">
									<h1>
										<Label number={88} />
									</h1>
									<select
										onChange={(e) => editCart({ chopsticks: e.target.value })}
										value={cart.chopsticks ? cart.chopsticks : ""}
									>
										{[...Array(15)].map((x, i) => (
											<option key={i} value={i}>
												{i}
											</option>
										))}
									</select>
								</div>
							)}
							{(settings.orders.enable_eatin == 1 ||
								(user && settings.orders.invoice == 1)) && (
								<div
									className={
										"box box-009" + (hasErrorCode("009") ? " has-error" : "")
									}
								>
									{settings.orders.enable_eatin == 1 && (
										<div className="checkbox">
											<input
												id="eatin-checkbox"
												type="checkbox"
												checked={cart.eatin == 1}
												onChange={(e) =>
													editCart({ eatin: e.target.checked ? 1 : 0 })
												}
											/>
											<label htmlFor="eatin-checkbox">
												<Label number={79} />
											</label>
										</div>
									)}
									{user && settings.orders.invoice == 1 && (
										<>
											<div className="checkbox">
												<input
													id="invoice-checkbox"
													type="checkbox"
													checked={cart.invoice == 1}
													onChange={(e) =>
														editCart({
															invoice: e.target.checked ? 1 : 0,
														})
													}
												/>
												<label htmlFor="invoice-checkbox">
													<Label number={93} />
												</label>
											</div>
											{getErrorCode("009") && (
												<div className="error-label">
													{getErrorCode("009")}
												</div>
											)}
											{cart.invoice == 1 && (
												<>
													{cart.availables_addresses_billing &&
														cart.availables_addresses_billing.length >
															0 && (
															<select
																onChange={(e) =>
																	editCart({
																		billing_address_id:
																			e.target.value,
																	})
																}
																value={
																	cart.billing_address_id
																		? cart.billing_address_id
																		: ""
																}
															>
																<option value=""></option>
																{cart &&
																	cart.availables_addresses_billing.map(
																		(address, i) => (
																			<option
																				key={i}
																				value={address.id}
																			>
																				{address.company +
																					" - " +
																					address.address +
																					" " +
																					address.number +
																					", " +
																					address.city}
																			</option>
																		)
																	)}
															</select>
														)}
													<Link className="text-link" to={"/userboard"}>
														<Label number={94} />
													</Link>
												</>
											)}
										</>
									)}
								</div>
							)}
							{(!settings.orders.disable_order_notes ||
								settings.orders.disable_order_notes != 1) && (
								<div className="box">
									<h1>
										<Label number={82} />
									</h1>
									<textarea
										onBlur={(e) => editCart({ notes: e.target.value })}
										defaultValue={cart.notes}
									/>
								</div>
							)}
						</div>
						<div
							id="box-001"
							className={
								"box box-003" +
								(hasErrorCode("001") || hasErrorCode("003") ? " has-error" : "")
							}
						>
							<h1>
								<Label number={23} />
							</h1>
							{getErrorCode("001") && (
								<div className="error-label">{getErrorCode("001")}</div>
							)}
							{getErrorCode("003") && (
								<div className="error-label">{getErrorCode("003")}</div>
							)}
							{cart.products.map((product, i) => (
								<div key={i} className="line">
									<div>
										{product.name}
										<div className="small">
											{product.variants.map((variant, i) => (
												<div key={i}>
													{variant.qty + "x " + variant.name}
												</div>
											))}
											{product.products?.map((subproduct, i) => (
												<div key={i}>
													{subproduct.qty + "x " + subproduct.name}
												</div>
											))}
											{product.notes && (
												<div
													dangerouslySetInnerHTML={{
														__html: product.notes,
													}}
												/>
											)}
										</div>
									</div>
									<div>
										{product.is_removable != 0 && (
											<PlusMinus
												qty={product.qty}
												onCange={(qty) => handleEditProduct(product, qty)}
											/>
										)}
									</div>
									<div>
										{product.discounted == 1 ? (
											<div>
												<span className="small smoot">
													<s>
														{currencyFormat(
															product?.original_price,
															cart?.shop?.currency
														)}
													</s>{" "}
												</span>
												{currencyFormat(
													product.total,
													cart?.shop?.currency
												)}
											</div>
										) : (
											<div>
												{currencyFormat(
													product.total,
													cart?.shop?.currency
												)}
											</div>
										)}
									</div>
								</div>
							))}
							{cart.discounts_fidelity?.length > 0 &&
								cart.discounts_fidelity.map((discount, i) => (
									<div key={i}>
										<div className="line subtotal">
											<div>
												<Label number={13} />
											</div>
											<div></div>
											<div>
												{currencyFormat(
													discount.total_before_discount,
													cart?.shop?.currency
												)}
											</div>
										</div>
										<div className="line">
											<div className="icon">
												<button
													className="delete-row-button"
													onClick={() =>
														deleteDiscountFidelity(discount.item_id)
													}
												>
													<i className="fas fa-trash" />
												</button>
												{discount.name}
											</div>
											<div></div>
											<div>
												-
												{currencyFormat(
													discount.price,
													cart?.shop?.currency
												)}
											</div>
										</div>
									</div>
								))}
							{cart.discounts_code?.length > 0 &&
								cart.discounts_code.map((discount, i) => (
									<div key={i}>
										<div className="line subtotal">
											<div>
												<Label number={13} />
											</div>
											<div></div>
											<div>
												{currencyFormat(
													discount.total_before_discount,
													cart?.shop?.currency
												)}
											</div>
										</div>
										<div className="line">
											<div className="icon">
												{discount.removable == 1 && (
													<button
														className="delete-row-button"
														onClick={() =>
															deleteDiscountCode(discount.item_id)
														}
													>
														<i className="fas fa-trash" />
													</button>
												)}
												{discount.name}
											</div>
											<div></div>
											<div>
												-
												{currencyFormat(
													discount.price,
													cart?.shop?.currency
												)}
											</div>
										</div>
									</div>
								))}
							{cart.credit == 1 && (
								<>
									<div className="line subtotal">
										<div>
											<Label number={13} />
										</div>
										<div></div>
										<div>
											{currencyFormat(
												cart.total_before_credit,
												cart?.shop?.currency
											)}
										</div>
									</div>
									<div className="line">
										<div>
											<Label number={113} />
										</div>
										<div></div>
										<div>
											{currencyFormat(
												cart.credit_total,
												cart?.shop?.currency
											)}
										</div>
									</div>
								</>
							)}
							{cart.delivery_type == "delivery" && (
								<>
									<div className="line subtotal">
										<div>
											<Label number={13} />
										</div>
										<div></div>
										<div>
											{currencyFormat(
												cart.total_before_delivery_price,
												cart?.shop?.currency
											)}
										</div>
									</div>
									<div className="line">
										<div>
											<Label number={14} />
										</div>
										<div></div>
										<div>
											{currencyFormat(
												cart.delivery_price,
												cart?.shop?.currency
											)}
										</div>
									</div>
								</>
							)}
							<div className="line total">
								<div>
									<Label number={15} />
								</div>
								<div></div>
								<div>{currencyFormat(cart.total, cart?.shop?.currency)}</div>
							</div>
							<div>
								{cart.order_min && cart.order_min > 0 && (
									<div className="line small">
										<div>
											<Label number={166} />
										</div>
										<div></div>
										<div>
											{currencyFormat(cart.order_min, cart?.shop?.currency)}
										</div>
									</div>
								)}
								{cart.delivery_type == "delivery" &&
									cart.delivery_free_min &&
									cart.delivery_free_min > 0 && (
										<div className="line small">
											<div>
												<Label number={167} />
											</div>
											<div></div>
											<div>
												{currencyFormat(
													cart.delivery_free_min,
													cart?.shop?.currency
												)}
											</div>
										</div>
									)}
							</div>
						</div>
						<div>
							<Marketing
								marketing={marketing}
								setMarketing={setMarketing}
								newsletter={newsletter}
								setNewsletter={setNewsletter}
							/>
							<Privacy />
							<button
								onClick={handlePayment}
								className="button button-primary button-block"
							>
								{cart.payment_method_id == 8 ? (
									findPaymentName(8)
								) : (
									<Label number={72} />
								)}
							</button>
							<Link
								className="button button-secondary button-block mt-2"
								to={"/store"}
							>
								<Label number={168} />
							</Link>
							{paymentPopupVisible && (
								<PaymentPopup
									onPayment={checkout}
									onCancel={() => setPaymentPopupVisible(false)}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Checkout;
