import { useNavigate } from "react-router-dom";
import Label from "../components/Label";
import api from "../components/api";
import { useContext } from "react";
import { AppContext } from "../App";

function LoginCheckEmail() {
	const { setLoading, setConfirm, tempUser, setTempUser } = useContext(AppContext);
	const navigate = useNavigate();

	const checkEmail = async (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		let request = Object.fromEntries(data.entries());
		setLoading(true);
		const loginRes = await api.post("/customers2/check_email/", request);
		setLoading(false);
		if (loginRes.success == 0)
			setConfirm({
				title: "Errore",
				message: loginRes.error,
			});
		else {
			console.log(loginRes.exist);
			setTempUser({ email: request.email, id: loginRes.id });
			if (loginRes.exist == 1) {
				if (loginRes.email_confirmed == 0) navigate("/check_code");
				else if (loginRes.force_reset_password == 1)
					setConfirm({
						title: "Errore",
						message: loginRes.error,
						onConfirm: () => navigate("/check_password_code"),
					});
				else navigate("/email_login");
			} else navigate("/signup");
		}
	};

	return (
		<div className="page">
			<div className="login">
				<div className="content">
					<h1>
						<Label number={35} />
					</h1>
					<form onSubmit={checkEmail}>
						<input
							type="email"
							name="email"
							placeholder="Email"
							defaultValue={tempUser.email}
							required
						/>
						<button className="button button-primary">
							<Label number={41} />
						</button>
						<button type="button" className="button" onClick={() => navigate("/login")}>
							<Label number={131} />
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}

export default LoginCheckEmail;
